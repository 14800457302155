/** @jsx jsx */
import { jsx } from 'theme-ui';

const Faq = () => {
  return (
    <section sx={styles.container}>
      <div sx={{ maxWidth: ['100%', '100%', '19rem'] }}>
        <h2 sx={styles.heading}>Frequently Asked Questions</h2>
        <p sx={styles.subHeading}>
          Can’t find the answer you’re looking for? Reach out to us.
        </p>
      </div>

      <ul sx={styles.questionList}>
        <li sx={styles.questionItem}>
          <h3>What is ScrapeOwl?</h3>
          <p>
            ScrapeOwl is a web scraping API that unlike other scraping APIs,
            only gets you the elements that you specify.
          </p>
        </li>

        <li sx={styles.questionItem}>
          <h3>Can I Scrape all Elements?</h3>
          <p>
            Yes, simply leave the element field empty when submitting a request
            and ScrapeOwl will return the full page.
          </p>
        </li>

        <li sx={styles.questionItem}>
          <h3>Do you have a free plan?</h3>
          <p>Yes, Sign up and you get 1000 credits for free!</p>
        </li>

        <li sx={styles.questionItem}>
          <h3>Need more credits or help scraping?</h3>
          <p>
            Reach out to us at team@scrapeowl.com and we will help you figure
            out a plan to accomplish your goals.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default Faq;

const styles = {
  container: {
    variant: 'layout.container',
    color: 'purplegray',
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: 'space-between',
    pt: [3, 4],
    pb: [5, 7, '129px'],
  },

  heading: {
    fontWeight: 'bold',
    fontSize: [5, 6, '56px'],
    mt: [2, 3, '24px'],
    lineHeight: 1.2,
  },

  subHeading: {
    fontWeight: 500,
    fontSize: [3, '19px'],
    opacity: 0.5,
    mt: [2, 2, '24px'],
  },

  questionItem: {
    bg: 'lightergray',
    py: [4, '37px'],
    px: [3, '40px'],
    borderRadius: '20px',
    mt: [4, 5, 'unset'],

    '&:not(:first-child)': {
      mt: '24px',
    },

    h3: {
      fontWeight: 600,
    },

    p: {
      mt: '18px',
      opacity: 0.54,
      fontWeight: 500,
      fontSize: '17px',
    },
  },

  questionList: {
    maxWidth: ['none', 'none', '40rem'],
  },
};

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as ExtractingDataIcon } from '../../assets/icons/extracting-data.svg';
import { ReactComponent as CaptchasIcon } from '../../assets/icons/captchas.svg';
import { ReactComponent as ResidentialProxiesIcon } from '../../assets/icons/residential-proxies.svg';
import { ReactComponent as JSRenderingIcon } from '../../assets/icons/js-rendering.svg';
import { ReactComponent as GeotargetingIcon } from '../../assets/icons/geo-targeting.svg';
import { ReactComponent as CookiesIcon } from '../../assets/icons/cookies.svg';

const CollectData = () => {
  return (
    <div
      sx={{ bg: 'lightergray', borderTop: '1px dashed rgba(0, 0, 0, 0.1)' }}
    >
      <div sx={styles.wrapper}>
        <div sx={styles.textWrapper}>
          <span sx={styles.subHeading}>Collect Data</span>

          <h2 sx={styles.heading}>
            <span>Scraping API</span>
            <span>No more proxies.</span>
            <span>No more browsers.</span>
            <span>Just data.</span>
          </h2>

          <p
            sx={{
              color: 'purplegray',
              fontSize: '1.25rem',
              lineHeight: '1.875rem',
              mt: '40px',
            }}
          >
            Focus on scraping the data you need, not on proxies, headless
            browsers, and HTML parsing.
          </p>

          <p sx={{ mt: '20px', lineHeight: '1.875rem', opacity: 0.5 }}>
            Just select the elements and we'll get them for you.
          </p>
        </div>

        <div sx={styles.boxWrapper}>
          <div sx={styles.box} className="white-bg">
            <ResidentialProxiesIcon />
            <h3>Residential Proxies</h3>
            <p>
              <span>Never get blocked. </span>
              <span sx={{ opacity: 0.54 }}>
                We only use the highest quality residential IP addresses to
                ensure reliability and uptime.
              </span>
            </p>
          </div>

          <div sx={styles.box} className="purple-bg">
            <JSRenderingIcon />
            <h3>JS Rendering</h3>
            <p>
              <span>Run chrome instances to scrape-at-scale </span>
              <span sx={{ opacity: 0.54 }}>
                without worrying about resource usage or browser and session
                management.
              </span>
            </p>
          </div>

          <div sx={styles.box} className="white-bg">
            <GeotargetingIcon />
            <h3>Geo-targeting</h3>
            <p>
              <span>Get country-specific results </span>
              <span sx={{ opacity: 0.54 }}>
                for platforms that use localization to display prices and
                descriptions like Amazon.fr vs Amazon.ae and eBay.
              </span>
            </p>
          </div>

          <div sx={styles.box} className="purple-bg">
            <CaptchasIcon />
            <h3>Bypassing Captchas</h3>
            <p>
              <span>Circumvent web security measures </span>
              <span sx={{ opacity: 0.54 }}>
                by getting data without triggering Catpchas on Cloudflare,
                Hcaptcha, Google recaptcha.
              </span>
            </p>
          </div>

          <div sx={styles.box} className="white-bg">
            <CookiesIcon />
            <h3>Cookies</h3>
            <p>
              <span>Get country-specific results </span>
              <span sx={{ opacity: 0.54 }}>
                for platforms that use localization to display prices and
                descriptions like Amazon.fr vs Amazon.ae and eBay.
              </span>
            </p>
          </div>

          <div sx={styles.box} className="white-bg">
            <ExtractingDataIcon />
            <h3>Extracting data</h3>
            <p>
              <span>Extract only the elements you need from a page </span>
              <span sx={{ opacity: 0.54 }}>
                without needing to parse html yourself.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectData;

const styles = {
  wrapper: {
    variant: 'layout.container',
    pt: [6, 7, '250px'],
    pb: [4, 5, '108px'],
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: 'space-between',
    fontWeight: 600,
  },

  textWrapper: {
    maxWidth: ['100%', '30rem', '22rem'],
    mr: [0, '2rem'],
    mb: [1, 4, 0],
  },

  boxWrapper: {
    maxWidth: ['100%', 'none', '65%'],
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: ['flex-start', 'flex-start', 'flex-end'],
    m: [0, '-1.688rem'],
    width: '100%',
  },

  box: {
    margin: [0, '12px'],
    mt: [4, '12px'],
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 600,
    maxWidth: ['100%', '22rem', '20rem'],
    px: '40px',
    py: '32px',

    h3: {
      fontSize: '1.375rem',
      fontWeight: 'bold',
      mt: '25px',
    },

    p: {
      mt: '13px',
    },

    '&.white-bg': {
      color: 'purplegray',
      boxShadow: '0px 0px 16px 2px rgba(0, 0, 0, 0.075)',
      border: '1px solid #D9DDE9',
      background:
        'radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FBFBFD 100%)',
    },

    '&.purple-bg': {
      color: 'white',
      boxShadow: '0px 0px 16px 2px rgba(0, 0, 0, 0.2)',
      border: '1px solid #272746',
      background:
        'radial-gradient(50% 50% at 50% 50%, #525376 0%, #404163 100%)',
    },
  },

  subHeading: {
    textTransform: 'uppercase',
    opacity: 0.5,
    color: 'purplegray',
    fontSize: '14px',
  },

  heading: {
    display: 'flex',
    flexDirection: 'column',
    color: 'purplegray',
    fontSize: [5, 5, '2.25rem'],
    lineHeight: '2.75rem',
    mt: '32px',
  },
};

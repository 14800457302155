/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as ProductPagesIcon } from '../../assets/icons/product-pages.svg';
import { ReactComponent as SocialContentIcon } from '../../assets/icons/social-content.svg';
import { ReactComponent as RealEstateIcon } from '../../assets/icons/real-estate.svg';
import { ReactComponent as StocksAndDataIcon } from '../../assets/icons/stocks-and-data.svg';
import { ReactComponent as SearchEngineResultsIcon } from '../../assets/icons/search-engine-results.svg';
import { ReactComponent as StocksImage } from '../../assets/images/stocks.svg';
import { ReactComponent as SearchEngineResultsImage } from '../../assets/images/search-engine-results.svg';
import { ReactComponent as ProductPagesImage } from '../../assets/images/product-pages.svg';
import { ReactComponent as SocialContentImage } from '../../assets/images/social-content.svg';
import { ReactComponent as RealEstateImage } from '../../assets/images/real-estate.svg';

const UseCases = () => {
  return (
    <section sx={styles.container}>
      <div sx={styles.containerInner}>
        <div sx={styles.intro}>
          <h2 sx={styles.heading}>Scrape. Scrape.</h2>
          <p sx={styles.subHeading}>
            <span>You name it. We scrape it. </span>Our scraping API and proxy
            is used in many ways
          </p>
        </div>

        <div sx={styles.grid}>
          <div sx={styles.gridItem}>
            <div>
              <SocialContentIcon />
            </div>
            <h3>Social Content</h3>
            <p>
              <span>
                Gather social profiles, photos, videos and other content
              </span>{' '}
              <span>
                — from Instagram, TikTok, LinkedIn and other social
                sites.
              </span>
            </p>
            <div className="imageWrapper">
              <SocialContentImage />
            </div>
          </div>

          <div sx={styles.gridItem}>
            <div>
              <ProductPagesIcon />
            </div>
            <h3>Product Pages</h3>
            <p>
              <span>
                Get products, prices, and descriptions from product listing
                pages on e-commerce platforms
              </span>{' '}
              <span>— like Amazon, eBay, and others.</span>
            </p>
            <div className="imageWrapper">
              <ProductPagesImage />
            </div>
          </div>

          <div sx={styles.gridItem}>
            <div>
              <StocksAndDataIcon />
            </div>
            <h3>Stocks and Data</h3>
            <p>
              <span>
                Keep an eye on the value of companies you invest in and stocks you trade
              </span>{' '}
              <span>
                — from TradingView, Google Finance, Yahoo Finance and other sources.
              </span>
            </p>
            <div
              className="imageWrapper"
              sx={{
                svg: {
                  mr: [-2, -5],
                },
              }}
            >
              <StocksImage />
            </div>
          </div>

          <div sx={styles.gridItem}>
            <div>
              <SearchEngineResultsIcon />
            </div>
            <h3>Search Engine Results</h3>
            <p>
              <span>Get page listings for each search engine</span>{' '}
              <span>
                Messages from customers with previous orders that request
                returns or exhanges.
              </span>
            </p>
            <div
              className="imageWrapper"
              sx={{
                svg: {
                  mr: [-2, -5],
                },
              }}
            >
              <SearchEngineResultsImage />
            </div>
          </div>

          <div sx={styles.gridItem}>
            <div>
              <RealEstateIcon />
            </div>
            <h3>Real Estate</h3>
            <p>
              <span>
                Collect products, prices, and descriptions from product listing
                pages on e-commerce platforms.
              </span>{' '}
              <span>We support Amazon, eBay, and others.</span>
            </p>
            <div
              className="imageWrapper"
              sx={{
                svg: {
                  mr: [-2, -6],
                },
              }}
            >
              <RealEstateImage />
            </div>
          </div>

          <div sx={{ ...styles.gridItem, ...styles.gridItemCta }}>
            <h3>Start Collecting Data </h3>
            <p>In addition to these, we cover thousands of other use cases! </p>
            <a href="http://app.scrapeowl.com/register">Sign up</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;

const styles = {
  container: {
    py: [5],
    bg: 'lightergray',
  },

  containerInner: {
    variant: 'layout.container',
  },

  intro: {},

  heading: {
    fontSize: [4, '3rem'],
    color: '#525376',
  },

  subHeading: {
    fontWeight: 'bold',
    fontSize: [3, '28px'],
    maxWidth: '35rem',
    color: 'rgba(165, 166, 185, 0.9)',

    'span:nth-of-type(1)': {
      color: 'rgba(82, 83, 118, 1)',
    },
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: [3, 4],
    mt: [3, '4.5rem'],
  },

  gridItem: {
    boxShadow: '2px 3px 16px rgba(0, 0, 0, 0.075)',
    borderRadius: '16px',
    bg: 'white',
    py: [2, 4],
    px: [2, 4],
    maxWidth: '36rem',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    '.imageWrapper': {
      alignSelf: 'center',
      mt: [3, 4],
    },

    h3: {
      mt: [2, 3],
      fontSize: '21px',
      fontWeight: '600',
      color: '#525376',
    },

    p: {
      maxWidth: '26rem',
      mt: 2,
      fontSize: '17px',
      fontWeight: 600,
      color: 'rgba(82, 83, 118, 0.5)',

      'span:first-of-type': {
        color: 'rgba(82, 83, 118, 1)',
      },
    },
  },

  gridItemCta: {
    boxShadow: 'unset',
    bg: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#525376',

    h3: {
      fontWeight: '600',
      letterSpacing: '-0.1px',
      color: '#525376',
      fontSize: '32px',
    },

    p: {
      fontSize: 3,
      fontWeight: '500',
      maxWidth: '320px',
    },

    a: {
      fontWeight: 'bold',
      fontSize: '16px',
      border: '1.22857px solid #DF7438',
      boxShadow: '0.927991px 0.927991px 3.71196px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
      mt: 3,
      color: 'white',
      px: '2.2rem',
      pt: '0.75rem',
      pb: '0.8rem',
      bg: '#FF8B49',
    },
  },
};

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as WorkflowsImage } from '../../assets/images/execute-workflows.svg';

const ExecuteWorkflows = () => {
  return (
    <section sx={styles.container}>
      <h2 sx={styles.heading}>Execute Workflows</h2>
      <div sx={{ display: 'flex', flexDirection: 'column', maxWidth: '23rem' }}>
        <span
          sx={{
            color: 'rgba(110, 113, 216, 1)',
            fontWeight: 'bold',
            fontSize: [4, '40px'],
          }}
        >
          custom_js
        </span>
        <span
          sx={{
            color: 'rgba(165, 166, 185, 1)',
            fontWeight: '500',
            fontSize: [3, '19px'],
          }}
        >
          Inject a JS script along with your request to a browser in the cloud.
        </span>
      </div>
      <div sx={styles.imageWrapper}>
        <WorkflowsImage />
      </div>
    </section>
  );
};

export default ExecuteWorkflows;

const styles = {
  container: {
    pt: [4, 5, 6],
    pb: [3, 4],
    variant: 'layout.container',
  },

  heading: {
    color: 'rgba(82, 83, 118, 1)',
    fontSize: [3, '48px'],
    mb: '24px',
  },

  imageWrapper: {},
};

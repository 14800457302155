/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as PostImage } from '../../assets/images/post.svg';

const Post = () => {
  return (
    <section sx={styles.container}>
      <div sx={styles.containerInner}>
        <div sx={styles.textWrapper}>
          <h2>POST</h2>
          <p>Fill a form or perform an action on any web page at scale.</p>
          <pre>
            <code>{`{
    "url: "https://example.com/post",
    "request_method: "POST",
    "post_data: "name=value&name1=value1"
}`}</code>
          </pre>
        </div>

        <div sx={styles.imageWrapper}>
          <PostImage />
        </div>
      </div>
    </section>
  );
};

export default Post;

const styles = {
  container: {
    bg: 'lightergray',
    borderTop: '1px dashed hsla(0, 0%, 0%, 0.2)',
    borderBottom: '1px dashed hsla(0, 0%, 0%, 0.2)',
    py: [4, 5, 6],
    my: 6,
  },

  containerInner: {
    variant: 'layout.container',
    display: 'flex',
    flexDirection: ['column', 'row'],
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  textWrapper: {
    h2: {
      color: 'rgba(110, 113, 216, 1)',
      fontWeight: 'bold',
      fontSize: '40px',
    },

    p: {
      color: 'rgba(165, 166, 185, 1)',
      fontWeight: '500',
      mt: '16px',
      fontSize: '19px',
      maxWidth: '280px',
    },

    pre: {
      color: '#fff',
      mt: '40px',
      padding: '20px 32px 20px 16px',
      border: 'solid 1px #07070c',
      borderRadius: '10px',
      background: '#5a5a70',
      fontSize: '15px',
      boxShadow: '2px 2px 4px 0px #efefef',
      },
    },

  imageWrapper: {
    width: '100%',
    maxWidth: '36rem',
  },
};
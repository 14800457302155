import React from 'react';
import Layout from '../components/layout';
import Hero from '../sections/homepage/hero';
import CompanyLogos from '../sections/homepage/company-logos';
import GettingStarted from '../sections/homepage/getting-started';
import CollectData from '../sections/homepage/collect-data';
import Usage from '../sections/homepage/usage';
import UseCases from '../sections/homepage/use-cases';
import Faq from '../sections/homepage/faq';
import Cta from '../sections/homepage/cta';
import Post from '../sections/homepage/post';
import ExecuteWorkflows from '../sections/homepage/execute-workflow';

const IndexPage = () => {
  return (
    <Layout title="Simple and Affordable Web Scraping API - ScrapeOwl">
      <Hero />
      <CompanyLogos />
      <GettingStarted />
      <CollectData />
      <Usage />
      <UseCases />
      <ExecuteWorkflows />
      <Post />
      <Faq />
      <Cta />
    </Layout>
  );
};

export default IndexPage;

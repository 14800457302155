/** @jsx jsx */
import { jsx } from "theme-ui";
import { ReactComponent as PavedLogo } from "../../assets/icons/paved-logo.svg";
import { ReactComponent as YkoneLogo } from "../../assets/icons/ykone-logo.svg";
import { ReactComponent as MnicasaLogo } from "../../assets/icons/mnicasa-logo.svg";
import { ReactComponent as UFFloridaLogo } from "../../assets/icons/uf-florida-logo.svg";
import { ReactComponent as VertuLogo } from "../../assets/icons/vertu-motors-logo.svg";

const CompanyLogos = () => {
  return (
    <section sx={styles.container}>
      <div sx={styles.containerInner}>
        <span>
          <UFFloridaLogo />
        </span>

        <span>
          <MnicasaLogo />
        </span>

        <span>
          <VertuLogo />
        </span>

        {/* <span>
          <YkoneLogo />
        </span> */}

        <span>
          <PavedLogo />
        </span>
      </div>
    </section>
  );
};

export default CompanyLogos;

const styles = {
  container: {
    //bg: 'lightergray',
    borderTop: "1px dashed hsla(0, 0%, 0%, 0.15)",
    borderBottom: "1px dashed hsla(0, 0%, 0%, 0.15)",
    pt: 4,
    pb: 3,
    my: 2,
  },

  containerInner: {
    display: "flex",
    flexWrap: "wrap",
    variant: "layout.container",
    justifyContent: ["center", "space-between"],
    alignItems: "center",

    span: {
      my: 3,
      mx: [3, 0],
    },
  },
};

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as TerminalIcon } from '../../assets/icons/terminal.svg';
import { ReactComponent as FlashIcon } from '../../assets/icons/flash.svg';

const Usage = () => {
  return (
    <div sx={styles.wrapperOuter}>
      <div sx={styles.wrapper}>
        <div sx={styles.cardWrapper}>
          <div sx={styles.iconWrapper}>
            <TerminalIcon />
          </div>

          <div sx={styles.cardTextWrapper}>
            <h2 sx={styles.cardHeading}>Use the API programmatically</h2>

            <p sx={styles.cardBody}>
              <span>Send requests to our API endpoint, </span>
              <a href="https://api.scrapeowl.com/v1/scrape">
                https://api.scrapeowl.com/v1/scrape
              </a>{' '}
              <span>to receive data in JSON or raw HTML.</span>
            </p>

            <div sx={styles.cardFooter}>
              <h3>Using programming languages such as:</h3>
              <p>
                <span>CURL, </span>
                <span>Python, </span>
                <span>Ruby, </span>
                <span>Node, </span>and others.
              </p>
            </div>
          </div>
        </div>

        <div sx={styles.cardWrapper}>
          <div sx={styles.iconWrapper}>
            <FlashIcon />
          </div>

          <div sx={styles.cardTextWrapper}>
            <h2 sx={styles.cardHeading}>Use the API with no code</h2>

            <p sx={styles.cardBody}>
              <span>
                Send requests to our scraping API from low-code services and
                output to different destinations like Google sheets and others.
              </span>
            </p>

            <div sx={styles.cardFooter}>
              <h3>Using programming languages such as:</h3>
              <p>
                <span>Integromat, </span>
                <span>Parabola, </span>
                <span>Zapier, </span> and
                <span> Postman.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usage;

const styles = {
  wrapperOuter: {
    bg: 'white',
    borderTop: '1px dashed hsla(0, 0%, 0%, 0.1)',
    borderBottom: '1px dashed hsla(0, 0%, 0%, 0.1)',
  },
  wrapper: {
    variant: 'layout.container',
    display: 'flex',
    flexDirection: ['column', 'row'],
    justifyContent: 'space-around',
    color: 'purplegray',
    fontWeight: 600,

    '& > *': {
      width: ['100%', '50%'],
      pb: '64px',
      pt: '70px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    '& > *:first-child': {
      borderRight: [0, 0, '1px dashed hsla(0, 0%, 0%, 0.1)'],
    },
  },
  iconWrapper: {
    width: '2em',
    height: '2em',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    mr: '12px',
    mt: [2, 2, 0],
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: ['100%', '45%', 'unset'],
  },
  cardTextWrapper: {
    maxWidth: '28rem',
    wordBreak: 'break-word',
  },
  cardHeading: {
    fontSize: [4, 4, '22px'],
    fontWeight: '600',
  },
  cardBody: {
    mt: [2, '16px'],
    fontWeight: 500,
    color: 'purplegray',
    fontSize: [3, 3, '18px'],
    span: {
      opacity: 0.75,
    },
    a: {
      color: 'purplegray',
      fontWeight: 600,
    },
  },

  cardFooter: {
    mt: '32px',
    h3: {
      fontSize: [2, 2, '14px'],
      fontWeight: 600,
      opacity: 0.5,
    },
    p: {
      mt: '10px',
      fontSize: [2, 2, '18px'],
    },
    span: {
      color: 'purple',
      fontWeight: 700,
    },
  },
};

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as CodeIcon } from '../../assets/icons/terminal.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';

const Hero = () => {
  return (
    <div sx={{ mt: 6 }}>
      <div sx={{ variant: 'layout.container' }}>
        <h1
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: 'purplegray',
            fontWeight: 700,
            fontSize: [5, '3rem'],
            letterSpacing: '-0.015em',
            lineHeight: ['130%', '130%'],
          }}
        >
          <span>Collect and access data instantly.</span>
          <span sx={{ opacity: 0.5 }}>From anywhere on the web. </span>
          <span sx={{ mt: 5, display: 'inline-block' }}>
            Automate workflows.
          </span>
        </h1>
{/* 
        <div sx={styles.featuresWrapper}>
          <div sx={styles.feature} className="is-first">
            <CodeIcon />
            <h2>Scraping API</h2>
            <p>
              A simple rest api to scrape any site on the web,proxy rotation,
              data parsing, and browser handling in the cloud.
            </p>
            <span>No-code support</span>
          </div>
        </div> */}

        <div sx={styles.statsContainer}>
      <div sx={styles.statsInner}>
        <div sx={styles.statsBox}>
          <div sx={styles.stat}>100M+ Pages Scraped</div>
          <div sx={styles.stat}>99% Success Rate</div>
          <div sx={styles.stat}>
            <div sx={styles.starBox}>
            <Star sx={styles.stars} />
            <Star sx={styles.stars} />
            <Star sx={styles.stars} />
            <Star sx={styles.stars} />
            <Star sx={styles.stars} />
            </div>
             Avg. Rating
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  );
};

export default Hero;

const styles = {
  featuresWrapper: {
    display: 'flex',
    flexDirection: ['column', 'row'],
    mt: [5, '96px'],

    '& > div:nth-child(1)': {
      mr: [0, '32px'],
      flexGrow: 13,
    },

    '& > div:nth-child(2)': {
      flexGrow: 10,
      mt: ['32px', 0],
    },
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 0.4,
    borderRadius: '22px',
    bg: 'lightgray',
    px: '40px',
    py: '35px',

    h2: {
      fontSize: ['22px'],
      mt: '8px',
    },

    p: {
      fontSize: '18px',
      mt: '8px',
      mb: 'auto',
    },

    span: {
      display: 'inline-block',
      fontSize: '12px',
      mt: '35px',
    },
  },
  statsContainer: {
    marginTop: '5rem',
    marginBottom: '4rem',
  },
  statInner: {

  },
  statsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',

  },
  stat: {
    color: '#696976',
    fontWeight: '600',
    fontSize: '16px',
    //paddingLeft: '16px',
    //background: '#e9e9f3',
    border: 'solid 1px #e9e9e9',
    padding: '16px 0px 8px',
    borderRadius: '8px',
    verticalAlign: 'middle',
    display: 'flex',
    width: '32.6%',
    justifyContent: 'center',
    boxShadow: '1px 2px 4px 0px #efefef',
  },

starBox: {
  mr: '12px',
},
star: {
  stroke: '#d6bb00',
  fill: '#efd100',
  width: '20px',
},

};

/** @jsx jsx */
import { jsx } from 'theme-ui';
import wave from '../../assets/illustrations/wave.svg';
import { ReactComponent as KeyIcon } from '../../assets/icons/key.svg';
import { ReactComponent as CodeAndFlashIcon } from '../../assets/icons/code-and-flash.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';

const GettingStarted = () => {
  return (
    <div sx={styles.container}>
      <div sx={styles.containerInner}>
        <div>
          <h2
            sx={{
              fontWeight: 700,
              color: 'purplegray',
              letterSpacing: '-1%',
              fontSize: [5, '32px'],
              lineHeight: '100%',
            }}
          >
            Getting Started
          </h2>
          <p
            sx={{
              fontSize: '20px',
              fontWeight: 500,
              letterSpacing: '1%',
              maxWidth: '320px',
              color: 'purplegray',
              mt: '20px'
            }}
          >
            <span><a href="https://scrapeowl.com/register" sx={{color: 'purplegray'}}>Create an account</a>, </span>
            <span sx={{ opacity: 0.5 }}>
              get your API key and start sending requests.
            </span>
          </p>
        </div>

        <div sx={styles.boxWrapper}>
          <div sx={styles.box}>
            <UserIcon />
            <h3>Sign up</h3>
            <p>Create an account and claim 1000 free credits.</p>
          </div>

          <div sx={styles.box}>
            <KeyIcon />
            <h3>Get your API key</h3>
            <p>Log in to your dashboard and copy your API key.</p>
          </div>

          <div sx={styles.box}>
            <CodeAndFlashIcon />
            <h3>Scrape</h3>
            <p>Send it via terminal, code, or no-code integrations.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;

const styles = {
  container: {
    variant: 'layout.container',
    mt: [5, 5],
    mb: [-2, -6, '-150px'],
  },

  containerInner: {
    display: 'flex',
    flexDirection: 'column',
    background: `url(${wave}) no-repeat 50% 80%`,
    px: [3, '0px'],
    pb: [3, '56px'],
  },

  boxWrapper: {
    mt: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: ['column', 'column', 'row'],

    '& > div:not(:last-child)': {
      mr: [0, 0, '24px'],
      mb: [4, 4, 0],
    },
  },

  box: {
    display: 'flex',
    flexDirection: 'column',
    px: [4, '40px'],
    py: [4, '30px'],
    background: '#fff',
    color: 'purplegray',
    borderRadius: '23px',
    border: 'solid 1px #efefef',
    fontWeight: 600,
    boxShadow: '2px 3px 8px rgba(0, 0, 0, 0.08)',

    h3: {
      mt: 1,
      opacity: 0.89,
      fontSize: '19px',
      fontWeight: '600',
    },

    svg: {
      opacity: 0.89,
      mb: 'auto',
    },

    p: {
      mt: '10px',
      fontSize: '16.4px',
      opacity: 0.54,
      fontWeight: '500',
    },
  },
};
